import React from "react";
import list from "./Services.json";
import "./Services.css";

export const Services = () => {
  return (
    <div className="color">
      <div className="MainLeft container">
        <h1 className="mb-5 Services_Heading">Services</h1>
        {list.map((elem) => {
          return (
            <div
              className={`${
                elem.id % 2 == 0 ? "opposite" : "MainImage"
              } space mx-auto`}
            >
              <div key={elem.id} className="order-1">
                <img
                  data-aos="flip-left"
                  src={elem.img}
                  alt="no"
                  className="ImgCls ImgCls2"
                  width={300}
                  height={220}
                />
              </div>
              <div className="MainText" data-aos="flip-right">
                <h3 className="headingServices">{elem.name}</h3>
                <p>{elem.desc}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
