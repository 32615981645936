import React from "react";
import "./home.css";

const Stratergy = (props) => {
  return (
    <div className="ProcedureMain strategy">
      {props.stratergy.map((stratergies) => {
        return (
          <div className="box" data-aos="fade-right">
            <h3 className="centrR fluid">{stratergies.heading}</h3>
            <p className="centr fluid">{stratergies.content}</p>
          </div>
        );
      })}
    </div>
  );
};

export default Stratergy;
