import React from "react";
import about from "./About/About.json";
import Stratergy from "./Home/Stratergy";
import "./About/About.css";
import { useState } from "react";
import { Container } from "react-bootstrap";
const AboutUs = () => {
  let [data, setData] = useState(about);

  return (
    <div className="aboutUs">
      <Container>
        <div className="item">
          <Stratergy stratergy={data} />
        </div>
      </Container>
    </div>
  );
};

export default AboutUs;
