import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../Images/6D LOGO.png";
import "./navbar.css";

function CollapsibleExample() {
  return (
    <Navbar
      fixed="top"
      collapseOnSelect
      expand="lg"
      bg="dark"
      variant="dark"
      className="nav"
    >
      <Container>
        <div className="logoimg">
          <img src={logo} alt="no" className="logo" />
          <Navbar.Brand className="Brand" href="#home">6D INFOMATIC</Navbar.Brand>
        </div>

        <div className="details">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link className="active links" href="/">HOME</Nav.Link>
              <Nav.Link className="active links" href="/about">ABOUT US</Nav.Link>
              <Nav.Link className="active links" href="/location">LOCATION</Nav.Link>
              <Nav.Link className="active links" href="/services">SERVICES</Nav.Link>
              <Nav.Link className="active links" href="/products">PRODUCTS</Nav.Link>
              <Nav.Link className="active links" href="/contactus">CONTACT US</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;
