import React from "react";
import logo from "../Images/6D LOGO.png";
import third from "../Images/6d Cover Wallpaper 3.jpg";
import sec from "../Images/6D Cover Wallpaper 2.jpg";
import first from "../Images/6d Cover Wallpaper  1.jpg";
import Carousel from "react-bootstrap/Carousel";
import "react-bootstrap";
import { useNavigate } from "react-router-dom";
import stratergy from "./stratergy.json";
import "./home.css";
import Stratergy from "./Stratergy";
import Procedure from "./Procedure";
import about from "../About/About.json";
import Video from "./Video";

const Home = () => {
  const navigate = useNavigate();
  return (
    <div className="Home">
      <Carousel className="centre fluid">
        <Carousel.Item style={{ width: "100vw", height: "auto" }}>
          <img className="d-block resize w-100" src={first} alt="First slide" />
          <Carousel.Caption className="bg">
            <img
              src={logo}
              alt="cre"
              className="animated"
              style={{ width: "20vw", height: "30vh" }}
            ></img>
            <h1 className="d__Home-heading tracking-in-expand">
              PERFECTION <span> IS </span> PRIORITY
            </h1>
            <button
              className="button_des"
              onClick={(e) => {
                navigate("/contactus");
              }}
            >
              CONTACT US
            </button>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item style={{ width: "100vw", height: "auto" }}>
          <img className="d-block resize w-100 " src={sec} alt="Second slide" />

          <Carousel.Caption className="bg">
            <img
              src={logo}
              alt="cre"
              className="animated"
              style={{ width: "20vw", height: "30vh" }}
            ></img>
            <h1 className="d__Home-heading tracking-in-expand">
              PERFECTION <span> IS </span> PRIORITY
            </h1>
            <button
              className="button_des"
              onClick={(e) => {
                navigate("/contactus");
              }}
            >
              CONTACT US
            </button>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item style={{ width: "100vw", height: "auto" }}>
          <img
            className="d-block w-100 resize "
            src={third}
            alt="Third slide"
          />

          <Carousel.Caption className="bg">
            <img
              src={logo}
              alt="cre"
              className="animated"
              style={{ width: "20vw", height: "30vh" }}
            ></img>
            <h1 className="d__Home-heading tracking-in-expand">
              PERFECTION <span> IS </span> PRIORITY
            </h1>
            <button
              className="button_des"
              onClick={(e) => {
                navigate("/contactus");
              }}
            >
              CONTACT US
            </button>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <h1>OUR PROCEDURES</h1>
      <Procedure />
      <h1>OUR WORKING STRATERGY</h1>
      <Stratergy stratergy={stratergy} />
      <h1>Elaborating our work</h1>
      <br/>
      <Video/>
      <h1>ABOUT US</h1>
      <Stratergy stratergy={about} />

    </div>
  );
};

export default Home;
