import React from "react";
import { Container } from "react-bootstrap";
import "./Location.css";
export default function Location() {
  return (
    <div className="location">
      <Container>
        <br />
        <br />
        <br />
        <br />
        <i>
          <h1>
            AVAILBLE <span> IN </span> LOCATION'S :-{" "}
          </h1>
        </i>
        <br />
        <img src="../Images/delhimap.PNG" alt="no" className="mapImg" />
      </Container>
    </div>
  );
}
