import React from "react";
import "./footer.css";
import { FcPhone } from "react-icons/fc";
import { MdEmail } from "react-icons/md";
import { ImFacebook } from "react-icons/im";
import { FiInstagram, FiYoutube } from "react-icons/fi";

const Footer = () => {
  return (
    <div className="d__footer ">
      <div className="footerLeft container">
        <h1>
          {" "}
          <span className="Red">6</span>D INFOMATIC{" "}
        </h1>
        <p class="sec">
          PERFECTION IS PRIORITY <br />
          <br />
          <a className="hoverMe" href="https://www.facebook.com/profile.php?id=100064174448353&mibextid=LQQJ4d">
            <ImFacebook /> - 6dinfomatic{" "}
          </a>
          <br />
          <a className="hoverMe" href="https://instagram.com/6dinfomatic?igshid=YmMyMTA2M2Y=">
            {" "}
            <FiInstagram />- 6dinfomatic{" "}
          </a>
          <br />
          <a className="hoverMe" href="https://youtube.com/@6Dinfomatic">
            {" "}
            <FiYoutube />- 6dinfomatic{" "}
          </a>
        </p>
      </div>
      <div className="right">
        <h2>Customer Care</h2>

        <p>
          <FcPhone /> Office Numeber - 011-43702602
          <br />

          <FcPhone /> Managing Director - 9899816928 <br />
          <hr />
          <MdEmail /> General Queries - Support@6dinfomatic.com 
          
        </p>
      </div>
    </div>
  );
};

export default Footer;
