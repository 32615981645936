import React from "react";
import procedure from "../Images/Our Procedure.png";
import project from "../Images/Project Management.png";
import brainStorm from '../Images/Brainstorm Tips Bulb Infographic Instagram Template.png';
import "./home.css";
const Procedure = () => {
  return (
    <div>
      <div className="ProcedureMain" data-aos="fade-right">
        <img className="Procedures image-fluid" src={procedure} alt="no" />
      </div>
      <div className="ProcedureMain" data-aos="fade-left">
      <h1>PROJECT MANAGEMENT</h1>
        <img className="Procedures image-fluid" src={project} alt="no" />
      </div>
      <div className="ProcedureMain" data-aos="fade-down">
      <h1>BRAINSTORM IDEA</h1>
        <img className="Procedures image-fluid" src={brainStorm} alt="no" />
      </div>
    </div>
  );
};

export default Procedure;
