import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./contactus.css";
const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_v0ezpsq",
        "template_d4k6trg",
        form.current,
        "A3T_fu39_AzzA-8Xy"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Querry has been sent");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="container bg2">
      <form className="d__Contact container" ref={form} onSubmit={sendEmail}>
        <h2 className="ConatactHeading">CONTACT US</h2>
        <label>Name</label>
        <input type="text" name="user_name" />
        <label>Email</label>
        <input type="email" name="user_email" />
        <label>Message</label>
        <textarea name="message" />
        <button className="bttn" type="submit" value="Send" > SUBMIT  </ button>
      </form>
 
    </div>
  );
};
export default ContactUs;
