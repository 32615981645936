import React from "react";
import Container from "react-bootstrap/Container";

import "./video.css";
const Video = () => {
  return (
    <div className="responsiveVideo">
      <Container className="griding">
        <div className="response">
          <iframe
            className="firstVideo"
            src="https://www.youtube.com/embed/0IpLFbviFSg"
            title="Scanning For New Development"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>

          <hr />
        </div>
        <div className="response">
          <iframe
            className="firstVideo"
            src="https://www.youtube.com/embed/dwXf5HJaHvs"
            title="Bike Scanning"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
            allowfullscreen
          ></iframe>
          <hr />
        </div>
        
        <div className="response">
          <iframe
            className="firstVideo"
            src="https://www.youtube.com/embed/EODcjEb0Qe4"
            title="3d Printing Proto"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <hr />
        </div>
      </Container>
    </div>
  );
};

export default Video;
