import React from "react";
import list from "./Products.json";
import "../Services/Services.css";
import "./Products.css";

export const Products = () => {
  return (
    <div className="color">
      <div className="MainLeft container">
        <h1 className="mb-5">
          <i className="style">
            {" "}
            SOME <span className="red"> CRITICAL</span> AND{" "}
            <span className="red">UNIQUE </span> DESIGN MADE BY US{" "}
          </i>
        </h1>
        {list.map((elem) => {
          return (
            <div 
              className={`${
                elem.id % 2 == 0 ? "MainImage" : "opposite"
              } prodSpecial space mx-auto container`}
            >
              <div key={elem.id} className="order-1" >
                <img src={elem.image} alt="no" className="ImgCls" data-aos="slide-right"/>
                <img src={elem.image2} alt="no" className="ImgCls" data-aos="slide-right"/>
                <img src={elem.image3} alt="no" className="ImgCls" data-aos="slide-right"/>
              </div>
              <div className="MainText col-lg-5" data-aos="slide-left">
                <h3 className="desc">{elem.description}</h3>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
