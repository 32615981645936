import "./App.css";
import { Home, ContactUs, Navbar, Footer } from "./components";
import Location from "./components/Location/Location";
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Services } from "./components/Services/Services";
import { Products } from "./components/Products/Products";
import AboutUs from "./components/About";

function App() {
  const [loading, SetLoading] = useState(true);
  const spinner = document.getElementById("spinner");
  if (spinner) {
    setTimeout(() => {
      SetLoading(false);
      spinner.style.display = "none";
    }, 1000);
  }
  return (
    !loading && (
      <>
        <Navbar />

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/about" element={<AboutUs />} />
          <Route exact path="/location" element={<Location />} />
          <Route exact path="/contactus" element={<ContactUs />} />
          <Route exact path="/products" element={<Products />} />
        </Routes>
        <Footer />
      </>
    )
  );
}
export default App;
